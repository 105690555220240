import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '../../components/Layout'
import Seo from '../../components/Seo'
import { seo, intro, faq } from '../../content/faq'
import { useLangContext } from '../../context/lang.context'
import Intro from '../../components/Intro'
import { sSection } from '../../style'
import Collapses from '../../components/Collapses'

const Faq = () => {
  const { lang } = useLangContext()

  const { image } = useStaticQuery(graphql`
    {
      image: file(absolutePath: { regex: "/images/intros/faq.jpg/" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(layout: FIXED, quality: 100, placeholder: BLURRED, formats: [JPG])
        }
      }
    }
  `)

  const button = {
    text: {
      pl: 'Czytaj',
      en: 'Read',
    },
    action: 'SCROLL',
  }

  // prettier-ignore
  return (
    <Layout>
      <Seo title={seo.title[lang]} desc={seo.desc[lang]} url={seo.url} keywords={seo.keywords}>
        <script type='application/ld+json'>
          {`{
              "@context": "https://schema.org", 
              "@type": "FAQPage", 
              "mainEntity": [
                ${faq.map(({ q, a }) => `{
                "@type": "Question", 
                "name": "${q}", 
                "acceptedAnswer": { 
                  "@type": "Answer", 
                  "text": "<p>${a}</p>"
                  }
                }`
          )}]}`}
        </script>
      </Seo>
      <Intro data={{ ...intro, button }} image={image.childImageSharp} position='bottom' />
      <section css={sSection}>
        <Collapses data={faq} fullWidth noGaps light />
      </section>
    </Layout>
  )
}

export default Faq
